import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import styles from "./ChartLine.module.css";
import ArrowsContainer from "./ArrowsContainer";
import configData from "../../config.json";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

const co2_total = configData.DATA.PUMS.CO2_TOTALE;
const nox_total = configData.DATA.PUMS.NOX_TOTALE;
const pm10_total = configData.DATA.PUMS.PM10_TOTALE;

const genova_total = configData.DATA.PUMS.GENOVA_TOTALE;
const imperia_total = configData.DATA.PUMS.IMPERIA_TOTALE;
const la_spezia_total = configData.DATA.PUMS.LA_SPEZIA_TOTALE;
const savona_total = configData.DATA.PUMS.SAVONA_TOTALE;

const roadConditionsM = configData.DATA.PUMS.CATTIVE_CONDIZIONI_STRADALI_MOLTO;
const connectionPublicTransportM =
  configData.DATA.PUMS.DIFFICOLTA_DI_COLLEGAMENTO_CON_MEZZI_PUBBLICI_MOLTO;
const parkingM = configData.DATA.PUMS.DIFFICOLTA_DI_PARCHEGGIO_MOLTO;
const roadIlluminationM =
  configData.DATA.PUMS.SCARSA_ILLUMINAZIONE_STRADALE_MOLTO;
const trafficM = configData.DATA.PUMS.TRAFFICO_MOLTO;

function ChartLine(props) {
  const {
    chartData,
    chartDataRounded,
    chartUnit,
    arrowsData,
    chartReferenceValue,
    axisRange,
    axisRangeCo2,
    axisRangeNox,
    axisRangePm10,
    mapDescription,
  } = props;

  const isPumsIndicator = props.activeIndicator.data.goalid === 18;
  const pumsReferenceValue = isPumsIndicator ? 2019 : null;

  // set textAnchor to middle to center the yAxis
  const yAxisTitle = document.querySelector(".recharts-text.recharts-label");
  if (yAxisTitle !== null) {
    yAxisTitle.classList.add(`${styles.yAxisTitle}`);
  }
  const threeCharts = props.activeIndicator.data.id === 25;
  console.log(chartDataRounded)
  console.log(axisRange)
  console.log(chartData)
  console.log(mapDescription);
  console.log(!threeCharts)
  return (
    <>
      {mapDescription && !threeCharts && (
        <div className={styles.container}>
          <ArrowsContainer
            chartData={chartData}
            arrowsData={arrowsData}
            activeIndicator={props.activeIndicator}
          />

          <ResponsiveContainer
            width="90%"
            height="100%"
            className={styles.responsive_container}
          >
            <LineChart
              className={styles.chart}
              data={chartDataRounded}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                className={styles.yAxisTitle}
                width={45}
                label={{
                  value: chartUnit,
                  angle: -90,
                  position: "insideLeft",
                  offset: -12,
                }}
                domain={axisRange}
              />
              <Tooltip />
              <ReferenceLine
                y={chartReferenceValue}
                x={pumsReferenceValue}
                strokeWidth={1}
                stroke="#cc008e"
                strokeDasharray="3 3"
              />
              <Legend />
              {chartData[0] !== undefined &&
                chartData[0][provinceData] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={provinceData}
                    stroke="#6aa84f"
                    strokeWidth={2}
                    activeDot={{ r: 6 }}
                    dot={{ r: 4 }}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][partCountryData_2] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={partCountryData_2}
                    stroke="#f1c232"
                    strokeWidth={2}
                    activeDot={{ r: 6 }}
                    dot={{ r: 4 }}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][partCountryData_1] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={partCountryData_1}
                    stroke="#f1c232"
                    strokeWidth={2}
                    activeDot={{ r: 6 }}
                    dot={{ r: 4 }}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][countryData] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={countryData}
                    stroke="#4bc9ff"
                    strokeWidth={2}
                    activeDot={{ r: 6 }}
                    dot={{ r: 4 }}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][euData] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={euData}
                    stroke="#0b4e8a"
                    strokeWidth={2}
                    activeDot={{ r: 6 }}
                    dot={{ r: 4 }}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][regionalData_FrontEnd] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={regionalData_FrontEnd}
                    stroke="#cc0000"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][genova_total] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={genova_total}
                    stroke="#6aa84f"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][imperia_total] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={imperia_total}
                    stroke="#0b4e8a"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][la_spezia_total] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={la_spezia_total}
                    stroke="#cc0000"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][savona_total] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={savona_total}
                    stroke="#f1c232"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
                {chartData[0] !== undefined &&
                chartData[0][roadConditionsM] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={roadConditionsM}
                    stroke="#f1c232"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
                {chartData[0] !== undefined &&
                chartData[0][connectionPublicTransportM] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={connectionPublicTransportM}
                    stroke="#cc0000"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
                {chartData[0] !== undefined &&
                chartData[0][parkingM] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={parkingM}
                    stroke="#0b4e8a"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
                {chartData[0] !== undefined &&
                chartData[0][roadIlluminationM] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={roadIlluminationM}
                    stroke="#6aa84f"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                )}
                {chartData[0] !== undefined &&
                chartData[0][trafficM] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={trafficM}
                    stroke="#4bc9ff"
                    strokeWidth={2}
                    activeDot={{ r: 6 }}
                    dot={{ r: 4 }}
                  />
                )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      {mapDescription && threeCharts && (
        <div className={styles.container}>
          <ArrowsContainer
            chartData={chartData}
            arrowsData={arrowsData}
            activeIndicator={props.activeIndicator}
          />
          <div className={styles.container_wrapper}>
            <ResponsiveContainer
              width="100%"
              height="100%"
              className={styles.responsive_multiply_container}
            >
              <LineChart
                className={styles.chart}
                data={chartDataRounded}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  className={styles.yAxisTitle}
                  width={45}
                  label={{
                    value: chartUnit,
                    angle: -90,
                    position: "insideLeft",
                    offset: -12,
                  }}
                  domain={axisRangeCo2}
                />
                <Tooltip />
                <ReferenceLine
                  y={chartReferenceValue}
                  x={pumsReferenceValue}
                  strokeWidth={1}
                  stroke="#cc008e"
                  strokeDasharray="3 3"
                />
                <Legend />
                {chartData[0] !== undefined &&
                  chartData[0][co2_total] !== undefined && (
                    <Line
                      type="monotone"
                      dataKey={co2_total}
                      stroke="#0b4e8a"
                      activeDot={{ r: 8 }}
                      dot={{ r: 4 }}
                      strokeWidth={2}
                    />
                  )}
              </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer
              width="100%"
              height="100%"
              className={styles.responsive_multiply_container}
            >
              <LineChart
                className={styles.chart}
                data={chartDataRounded}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  className={styles.yAxisTitle}
                  width={45}
                  label={{
                    value: chartUnit,
                    angle: -90,
                    position: "insideLeft",
                    offset: -12,
                  }}
                  domain={axisRangeNox}
                />
                <Tooltip />
                <ReferenceLine
                  y={chartReferenceValue}
                  x={pumsReferenceValue}
                  strokeWidth={1}
                  stroke="#cc008e"
                  strokeDasharray="3 3"
                />
                <Legend />
                {chartData[0] !== undefined &&
                  chartData[0][nox_total] !== undefined && (
                    <Line
                      type="monotone"
                      dataKey={nox_total}
                      stroke="#cc0000"
                      activeDot={{ r: 8 }}
                      dot={{ r: 4 }}
                      strokeWidth={2}
                    />
                  )}
              </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer
              width="100%"
              height="100%"
              className={styles.responsive_multiply_container}
            >
              <LineChart
                className={styles.chart}
                data={chartDataRounded}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  className={styles.yAxisTitle}
                  width={45}
                  label={{
                    value: chartUnit,
                    angle: -90,
                    position: "insideLeft",
                    offset: -12,
                  }}
                  domain={axisRangePm10}
                />
                <Tooltip />
                <ReferenceLine
                  y={chartReferenceValue}
                  x={pumsReferenceValue}
                  strokeWidth={1}
                  stroke="#cc008e"
                  strokeDasharray="3 3"
                />
                <Legend />
                {chartData[0] !== undefined &&
                  chartData[0][pm10_total] !== undefined && (
                    <Line
                      type="monotone"
                      dataKey={pm10_total}
                      stroke="#6aa84f"
                      activeDot={{ r: 8 }}
                      dot={{ r: 4 }}
                      strokeWidth={2}
                    />
                  )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(ChartLine);
