import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import styles from "./MapSlider.module.css";

function MapSlider(props) {
  const dataExists = props.legendRange.length !== 0;

  function findExistingYears(currentYears, activeYear) {
    const activeYears = [];
    currentYears.forEach((year) => activeYears.push(year.value));
    const isYearActive = !!activeYears.includes(activeYear);
    return isYearActive;
  }

  function onChangeHandler(event) {
    const yearExists = findExistingYears(
      props.currentYears,
      event.target.value
    );
    if (yearExists) {
      props.setCurrentYear(event.target.value);
    }
  }

  return (
    <div className={styles.sliderContainer}>
      <Box
        sx={{
          width: "calc(100% - 4rem)",
          marginTop: "2.5rem",
          marginLeft: "2rem",
        }}
      >
        {dataExists && (
          <Slider
            defaultValue={props.currentYear}
            aria-valuetext="none"
            min={props.currentYears[0].value}
            max={props.currentYears[props.currentYears.length - 1].value}
            step={null}
            valueLabelDisplay="on"
            marks={props.currentYears}
            onChange={onChangeHandler}
          />
        )}
      </Box>
    </div>
  );
}
export default MapSlider;
