import React, { useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import useHttp from "./hooks/use-http";
import "./App.css";
import ContentContainer from "./components/ContentContainer/ContentContainer";
import DescriptionSidebar from "./components/DescriptionSidebar/DescriptionSidebar";
import LegendSidebar from "./components/LegendSidebar/LegendSidebar";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import DevelopmentTrend from "./routes/DevelopmentTrend";
import Wheel from "./routes/Wheel";
import Footer from "./UI/Footer";
import GoalsDefinition from "./routes/GoalsDefinitions";
import configData from "./config.json";

import goal_4 from "./data/Goals/Goal_04.svg";
import goals from "./helper/takeGoals";

function App() {
  const descriptionSidebarBigScreenRef = useRef();
  const [activeGoal, setActiveGoal] = useState({
    goalIcon: goal_4,
    id: 4,
    nodeElement: null,
  });

  const [mobileLegendActive, setMobileLegendActive] = useState(false);
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileScreen, setIsMobileScreen] = useState();
  const [isBigScreen, setIsBigScreen] = useState();
  const { isLoading, error, sendRequest: fetchData } = useHttp();
  const [currentTargets, setCurrentTargets] = useState([]);
  const [activeIndicator, setActiveIndicator] = useState(null);
  const [activeTarget, setActiveTarget] = useState(null);
  const [wheelActive, setWheelActive] = useState(true);
  const [navigationBarActive, setNavigationBarActive] = useState({
    dashboardActive: false,
    sdgPageActive: false,
    developmentPageActive: false,
    infoPageActive: false,
  });

  let resizeTimer;
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
    document.body.classList.add("resize-animation-stopper");
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      document.body.classList.remove("resize-animation-stopper");
    }, 400);
  });
  window.addEventListener("load", () => {
    setWidth(window.innerWidth);
  });
  useEffect(() => {
    if (width <= 800) {
      setIsMobileScreen(true);
      setMobileNavActive(false);
    } else {
      setIsMobileScreen(false);
    }
    if (width > 1260) {
      setIsBigScreen(true);
    } else {
      setIsBigScreen(false);
    }
    return () => {
      window.removeEventListener("resize", setWidth(window.innerWidth));
    };
  }, [width]);
  function assignIconsToTarget(targets) {
    const targetsWithIcons = [];
    targets.forEach((target, targetId) => {
      const goal = goals[target.goalid - 1];
      goal.targetIcons.forEach((icon, index) => {
        if (targetId === index) {
          targetsWithIcons.push({
            ...target,
            icon,
            color: goal.color,
            colorScale: goal.colorScale,
          });
        }
      });
    });
    return targetsWithIcons;
  }
  function deleteNotPreparedIndicators(dataToClean) {
    const indicatorsToDelete = [];
    // check if indicator is on the list to delete
    const cutTargets = [];
    dataToClean.targets.map((target) => {
      const cutIndicators = [];
      target.indicators.forEach((indicator) => {
        if (!indicatorsToDelete.includes(indicator.id)) {
          cutIndicators.push(indicator);
        }
      });
      target.indicators = [...cutIndicators];
      if (cutIndicators.length > 0) {
        cutTargets.push(target);
      }
      return cutTargets;
    });

    dataToClean.targets = [...cutTargets];
  }

  const handleData = (data) => {
    console.log(data);
    // Delete indicators for which we don't have data
    deleteNotPreparedIndicators(data);
    const updatedTargets = assignIconsToTarget(data.targets);
    setCurrentTargets(updatedTargets);
    setActiveIndicator({
      data: data.targets[0].indicators[0],
      nodeElement: null,
    });
    currentTargets.push(updatedTargets);
  };

  function setIndicatorNodeAsData() {
    const isNotFirstRender =
      activeIndicator !== null &&
      descriptionSidebarBigScreenRef.current !== null &&
      descriptionSidebarBigScreenRef.current !== undefined;
    if (
      isNotFirstRender &&
      (activeIndicator.nodeElement === null ||
        activeIndicator.data.id !==
          Number(activeIndicator.nodeElement.getAttribute("id")))
    ) {
      const sidebar = descriptionSidebarBigScreenRef.current.children;
      Array.from(sidebar).forEach((container) => {
        Array.from(container.children).forEach((node) => {
          if (Number(node.getAttribute("id")) === activeIndicator.data.id) {
            setActiveIndicator((prevState) => ({
              ...prevState,
              nodeElement: node,
            }));
          }
        });
      });
    }
  }
  useEffect(() => {
    if (descriptionSidebarBigScreenRef.current !== null) {
      setIndicatorNodeAsData();
    }
  }, [descriptionSidebarBigScreenRef.current, setIndicatorNodeAsData]);

  useEffect(() => {
    if (activeIndicator !== null && currentTargets.length !== 0) {
      currentTargets.map((target) => {
        const targetId = activeIndicator.nodeElement
          ? activeIndicator.nodeElement.attributes.targetid.value
          : activeIndicator.data.targetid;

        if (targetId === target.id) {
          setActiveTarget(target);
        }
      });
    }
  }, [currentTargets, activeIndicator]);

  useEffect(() => {
    if (activeGoal != null) {
      const goalId = activeGoal.id;
      fetchData(
        {
          url: `${configData.SERVER_URL}/goals/${goalId}/`,
        },
        handleData
      );
    }
  }, [activeGoal]);

  return (
    <>
      {wheelActive && (
        <div className="wheelContainer">
          <Wheel
            setWheelActive={setWheelActive}
            wheelActive={wheelActive}
            setNavigationBarActive={setNavigationBarActive}
            isMobileScreen={isMobileScreen}
          />
          <Footer />
        </div>
      )}
      <div className="app_container">
        <div className={"accessibilityDivNotShown"}>
          <a href="#maincontent"/>
        </div>
        <div className="navigation_bar" id="maincontent">
          <NavigationBar
            mobileLegendActive={mobileLegendActive}
            setMobileLegendActive={setMobileLegendActive}
            mobileNavActive={mobileNavActive}
            setMobileNavActive={setMobileNavActive}
            isMobileScreen={isMobileScreen}
            setIsMobileScreen={setIsMobileScreen}
            setWheelActive={setWheelActive}
            wheelActive={wheelActive}
            setNavigationBarActive={setNavigationBarActive}
            navigationBarActive={navigationBarActive}
          />
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {isLoading && (
                  <div className="white_background">
                    <div className="message_container loading">
                      <p className="loading_text">Loading data...</p>
                    </div>
                  </div>
                )}
                {error && (
                  <div className="white_background">
                    <div className="message_container error">
                      <p className="error_text">{error}</p>
                    </div>
                  </div>
                )}

                <div className="description_sidebar">
                  <DescriptionSidebar
                    ref={descriptionSidebarBigScreenRef}
                    isLoading={isLoading}
                    error={error}
                    setActiveIndicator={setActiveIndicator}
                    currentTargets={currentTargets}
                    activeIndicator={activeIndicator}
                    activeTarget={activeTarget}
                    setActiveTarget={setActiveTarget}
                    goals={goals}
                    isBigScreen={isBigScreen}
                  />
                </div>
                <div className="content_container">
                  <ContentContainer
                    currentTargets={currentTargets}
                    activeIndicator={activeIndicator}
                    activeTarget={activeTarget}
                    activeGoal={activeGoal}
                    width={width}
                  />
                </div>

                <div
                  className={
                    mobileLegendActive
                      ? `legend_sidebar open_mobile_legend_sidebar`
                      : "legend_sidebar"
                  }
                >
                  <LegendSidebar
                    activeGoal={activeGoal}
                    setActiveGoal={setActiveGoal}
                    goals={goals}
                    setMobileLegendActive={setMobileLegendActive}
                    mobileLegendActive={mobileLegendActive}
                    isMobileScreen={isMobileScreen}
                  />
                </div>
                <div className="footer_container">
                  <Footer className="footer" />
                </div>
              </>
            }
          />
          <Route path="/indicatori_SDG" element={<GoalsDefinition />} />
          <Route
            path="/trend_di_sviluppo"
            element={
              <DevelopmentTrend
                setNavigationBarActive={setNavigationBarActive}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
