import { useState } from "react";
import styles from "./BesInfo.module.css";
import besLogo from "../../data/BesLogo.svg";

function BesInfo() {
  const [infoBoxActive, setInfoBoxActive] = useState(false);

  function onClickHandler() {
    setInfoBoxActive(!infoBoxActive);
  }

  const containerStyle = `${styles.container}`;
  const textStyle = ` ${styles.infoText}`;
  const infoIconStyle = `${styles.icon}`;

  return (
    <>
      <img
        alt="#"
        src={besLogo}
        className={infoIconStyle}
        onClick={onClickHandler}
        id="besIcon"
      />

      {infoBoxActive && (
        <div className={containerStyle}>
          <p className={textStyle}>
            Indicatore contenuto nel Rapporto BES, l&apos;analisi compilata ogni
            anno da Istat circa il Benessere Equo e Sostenibile in Italia.
          </p>
        </div>
      )}
    </>
  );
}

export default BesInfo;
