import { useEffect, useRef } from "react";
import styles from "./LegendSidebar.module.css";
import Sidebar from "../../UI/Sidebar";

function LegendSidebar(props) {
  const sidebarRef = useRef();

  // when we open a dashboard we will look for the active goal and we will add a class first_goal_active
  // to activate color, then it will be removed when we click on sidebarLegend element
  useEffect(() => {
    const goalActive = document.body.querySelector(
      `.goalIcon_${props.activeGoal.id}`
    );
    goalActive.classList.add(`first_goal_active`);
  }, []);

  const onClickHandler = (event) => {
    props.setActiveGoal((prevState) => ({
      ...prevState,
      goalIcon: event.target.src,
      id: event.target.id,
      nodeElement: event.target,
    }));
    if (props.isMobileScreen) {
      props.setMobileLegendActive(!props.mobileLegendActive);
    }

    // delete  the goal which was activated after clicking the wheel
    const wheelActivatedGoal =
      document.body.querySelector(".first_goal_active");
    if (wheelActivatedGoal !== null) {
      wheelActivatedGoal.classList.remove("first_goal_active");
    }

    event.target.classList.add(`${styles.indicator_active}`);
    if (
      props.activeGoal.nodeElement != null &&
      props.activeGoal.id !== event.target.id
    ) {
      props.activeGoal.nodeElement.classList.remove(
        `${styles.indicator_active}`
      );
    }
  };

  const goals = props.goals.map((item) => {
    let active = true;
    if (
      item.id === 1 ||
      item.id === 2 ||
      item.id === 3 ||
      item.id === 5 ||
      item.id === 7 ||
      item.id === 9 ||
      item.id === 14 ||
      item.id === 17
    ) {
      active = false;
    } else {
      active = true;
    }
    const indicatorStyles = active
      ? `${styles.indicator}`
      : `${styles.indicator} ${styles.disactive}`;
    return (
      <img
        alt={active ? item.goalTitle : `${item.goalTitle}_non attivo`}
        className={`${indicatorStyles} goalIcon_${item.id}`}
        src={item.goalIcon}
        onClick={onClickHandler}
        onKeyPress={(event) => event.code==='Enter' ? onClickHandler(event) : ''}
        id={item.id}
        key={item.id}
        title={active ? item.goalTitle : `${item.goalTitle}_non attivo`}
        tabIndex={active ? "1" : "none"}
      />
    );
  });
  return (
    <Sidebar>
      <div className={styles.container} ref={sidebarRef}>
        {goals}
      </div>
    </Sidebar>
  );
}
export default LegendSidebar;
