import React from "react";

import ChartLine from "./ChartLine";
import ChartBar from "./ChartBar";
import configData from "../../config.json";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

const co2_total = configData.DATA.PUMS.CO2_TOTALE;
const nox_total = configData.DATA.PUMS.NOX_TOTALE;
const pm10_total = configData.DATA.PUMS.PM10_TOTALE;

const genova_total = configData.DATA.PUMS.GENOVA_TOTALE;
const imperia_total = configData.DATA.PUMS.IMPERIA_TOTALE;
const la_spezia_total = configData.DATA.PUMS.LA_SPEZIA_TOTALE;
const savona_total = configData.DATA.PUMS.SAVONA_TOTALE;

const roadConditionsM = configData.DATA.PUMS.CATTIVE_CONDIZIONI_STRADALI_MOLTO;
const connectionPublicTransportM =
  configData.DATA.PUMS.DIFFICOLTA_DI_COLLEGAMENTO_CON_MEZZI_PUBBLICI_MOLTO;
const parkingM = configData.DATA.PUMS.DIFFICOLTA_DI_PARCHEGGIO_MOLTO;
const roadIlluminationM =
  configData.DATA.PUMS.SCARSA_ILLUMINAZIONE_STRADALE_MOLTO;
const trafficM = configData.DATA.PUMS.TRAFFICO_MOLTO;



function Chart(props) {
  const {
    chartData,
    chartUnit,
    arrowsData,
    chartReferenceValue,
    mapDescription,
    searchKeywordExists,
    searchKeyword,
  } = props;
  const chartDataRounded = [];

  const values = [];
  const valuesCo2 = [];
  const valuesNox = [];
  const valuesPm10 = [];
  function findAllValues(object) {
    Object.entries(object).map((arrayElement) => {
      if (
        arrayElement[0] !== "name" &&
        arrayElement[1] !== null &&
        (arrayElement[0] === regionalData_FrontEnd ||
          arrayElement[0] === countryData ||
          arrayElement[0] === provinceData ||
          arrayElement[0] === partCountryData_1 ||
          arrayElement[0] === partCountryData_2 ||
          arrayElement[0] === euData ||
          arrayElement[0] === genova_total ||
          arrayElement[0] === imperia_total ||
          arrayElement[0] === la_spezia_total ||
          arrayElement[0] === savona_total ||
          arrayElement[0] === roadConditionsM || 
          arrayElement[0] === connectionPublicTransportM || 
          arrayElement[0] === parkingM || 
          arrayElement[0] === roadIlluminationM ||
          arrayElement[0] === trafficM)
      ) {
        values.push(parseFloat(arrayElement[1]).toFixed(2));
      } else if (
        arrayElement[0] !== "name" &&
        arrayElement[1] !== null &&
        arrayElement[0] === co2_total
      ) {
        valuesCo2.push(parseFloat(arrayElement[1]).toFixed(2));
      } else if (
        arrayElement[0] !== "name" &&
        arrayElement[1] !== null &&
        arrayElement[0] === nox_total
      ) {
        valuesNox.push(parseFloat(arrayElement[1]).toFixed(2));
      } else if (
        arrayElement[0] !== "name" &&
        arrayElement[1] !== null &&
        arrayElement[0] === pm10_total
      ) {
        valuesPm10.push(parseFloat(arrayElement[1]).toFixed(2));
      }
    });
  }

  function roundValues(object) {
    let updatedValue = {
      name: object.name,
    };
    Object.entries(object).forEach((arrayElement, index) => {
      if (index !== 0) {
        const yearName = arrayElement[0];
        const yearData =
          arrayElement[1] !== null
            ? parseFloat(arrayElement[1]).toFixed(2)
            : null;
        updatedValue = {
          ...updatedValue,
          [`${yearName}`]: yearData,
        };
      }
    });
    return updatedValue;
  }
  // store all the values in the array so as to use it later to find the axis range and round the values so as the rounded values will be displayed on the tooltip
  chartData.forEach((oneYearAllTypesData) => {
    findAllValues(oneYearAllTypesData);
    console.log(values);
    console.log(oneYearAllTypesData)
    const roundedValue = roundValues(oneYearAllTypesData);
    console.log(roundedValue)
    chartDataRounded.push(roundedValue);
  });

  // set range of the chart ( if reference value is smaller than min it should be min, if reference value is bigger than max it should be max)
  let axisRange = [
    parseFloat((Math.min(...values) - 1).toFixed(1)),
    parseFloat((Math.max(...values) + 2).toFixed(1)),
  ];

  if (axisRange[0] < 0) {
    axisRange = [0, parseFloat((Math.max(...values) + 2).toFixed(1))];
  }

  if (chartReferenceValue !== undefined && chartReferenceValue < axisRange[0]) {
    axisRange = [
      chartReferenceValue - 2,
      parseFloat((Math.max(...values) + 2).toFixed(1)),
    ];
  } else if (
    chartReferenceValue !== undefined &&
    chartReferenceValue > axisRange[1]
  ) {
    axisRange = [
      parseFloat((Math.min(...values) - 1).toFixed(1)),
      chartReferenceValue + 2,
    ];
  }

  // workaround to show min value 0 for the indicator 23 and 33
  console.log(props.activeIndicator)
  if (props.activeIndicator !== null && props.activeIndicator.data.id === 23) {
    axisRange = [0, parseFloat((Math.max(...values) + 2).toFixed(1))];
  } else if (
    props.activeIndicator !== null &&
    props.activeIndicator.data.id === 33
  ) {
    axisRange = [
      -parseFloat((Math.max(...values) + 5).toFixed(1)),
      parseFloat((Math.max(...values) + 5).toFixed(1)),
    ];
  }

  // set axis range for indicator 25 ( we will have 3 different axis ranges)
  let axisRangeCo2 = [];
  let axisRangeNox = [];
  let axisRangePm10 = [];

  if (props.activeIndicator !== null && props.activeIndicator.data.id === 25) {
    axisRangeCo2 = [
      parseFloat((Math.min(...valuesCo2) - 1).toFixed(1)),
      parseFloat((Math.max(...valuesCo2) + 2).toFixed(1)),
    ];
    axisRangeNox = [
      parseFloat((Math.min(...valuesNox) - 1).toFixed(1)),
      parseFloat((Math.max(...valuesNox) + 2).toFixed(1)),
    ];
    axisRangePm10 = [
      parseFloat((Math.min(...valuesPm10) - 1).toFixed(1)),
      parseFloat((Math.max(...valuesPm10) + 2).toFixed(1)),
    ];
  }
  console.log(props.chartType.line);
  console.log(chartData);
  console.log(chartDataRounded)
  return (
    <>
      {props.chartType.line && (
        <ChartLine
          chartData={chartData}
          chartDataRounded={chartDataRounded}
          chartUnit={chartUnit}
          chartReferenceValue={chartReferenceValue}
          axisRange={axisRange}
          axisRangeCo2={axisRangeCo2}
          axisRangeNox={axisRangeNox}
          axisRangePm10={axisRangePm10}
          arrowsData={arrowsData}
          mapDescription={mapDescription}
          activeIndicator={props.activeIndicator}
        />
      )}
      {props.chartType.bar && (
        <ChartBar
          chartData={chartData}
          chartDataRounded={chartDataRounded}
          chartUnit={chartUnit}
          axisRange={axisRange}
          arrowsData={arrowsData}
          mapDescription={mapDescription}
          searchKeywordExists={searchKeywordExists}
          searchKeyword={searchKeyword}
          chartReferenceValue={chartReferenceValue}
          activeIndicator={props.activeIndicator}
        />
      )}
    </>
  );
}

export default React.memo(Chart);
