import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";

function SelectBox(props) {
  const [updatedIndicator, setUpdatedIndicator] = useState(null);
  const { targetColor } = props;
  const options = useMemo(() => {
    let result = [];
    props.indicators.map((indicator) => {
      result = [
        ...result,
        {
          ...indicator,
          value: indicator.description,
          label: indicator.description,
        },
      ];
    });
    return result;
  }, []);

  const onChangeHandler = (event) => {
    props.currentTargets.forEach((target) => {
      if (event.targetid === target.id) {
        props.setActiveTarget(target);
      }
    });
    props.setActiveIndicator((prevState) => ({ ...prevState, data: event }));
  };

  const customStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
        fontSize: "0.9em",
        border: "none",
        color: state.isSelected ? "white" : "black",
        background: state.isSelected ? `${targetColor}` : "white",
        cursor: "pointer",
        "&:hover": {
          background: `${targetColor}`,
          color: "white",
        },
        "@media only screen and (min-width: 800px)": {
          fontSize: "1.1em",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        fontSize: "0.9em",
        border: state.isFocused ? "2px solid hsl(0, 0%, 80%)" : "none",
        borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "none",
        boxShadow: "0 0 5px rgba(0,0,0,0.2)",
        background: "transparent",
        cursor: "pointer",
        padding: "0.5em",
        "&:hover": {
          border: "2px solid hsl(0, 0%, 80%)",
          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
        },
        "@media only screen and (min-width: 800px)": {
          fontSize: "1.1em",
        },
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? "hsl(0, 0%, 80%)" : "white",

        border: "none",

        "&:hover": {
          color: "hsl(0, 0%, 80%)",
        },
      }),
      indicatorSeparator: (provided, state) => ({}),

      singleValue: (provided, state) => ({
        ...provided,
        color: state.data.color,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    }),
    []
  );

  function findActiveIndicator() {
    props.currentTargets.forEach((target) => {
      target.indicators.forEach((indicator) => {
        if (indicator.id === Number(props.activeIndicator.data.id)) {
          setUpdatedIndicator({
            ...indicator,
            value: indicator.description,
            label: indicator.description,
          });
        }
      });
    });
  }

  useEffect(() => {
    findActiveIndicator();
  }, [props.activeIndicator, props.currentTargets]);

  return (
    <Select
      menuPortalTarget={document.body}
      options={options}
      value={updatedIndicator}
      onChange={onChangeHandler}
      styles={customStyles}
      isSearchable={false}
    />
  );
}
export default SelectBox;
