import styles from "./Logo.module.css";
import wheelIcon from "../../data/wheel/wheel.svg";
import logoTextIcon from "../../data/wheel/Logo_Text_Genova.svg";

function Logo(props) {
  const onClickLogoHandler = () => {
    props.setWheelActive(!props.wheelActive);
  };
  return (
    <>
      <img
        className={styles.logo_img}
        src={wheelIcon}
        alt="Datalab dashboard di monitoraggio degli obiettivi di sviluppo sostenibile"
        onClick={onClickLogoHandler}
        onKeyPress={(event) => event.code==='Enter' ? onClickLogoHandler() : ''}
        tabIndex="1"
      />
      <h1 style={{margin:0,display: 'flex'}}>
      <img
        className={styles.textLogo_img}
        src={logoTextIcon}
        alt="Datalab Città Metropolitana di Genova"
        onClick={onClickLogoHandler}
        onKeyPress={(event) => event.code==='Enter' ? onClickLogoHandler() : ''}
        tabIndex="1"
      />
      </h1>
    </>
  );
}
export default Logo;
