function handleLayout() {
  const wrapper = document.querySelector("#wheel_wrapper");
  const fotterImage = document.querySelector("#genova_img");
  const footer = document.querySelector("#footer");

  if (fotterImage !== null && wrapper !== null && footer !== null) {
    const fotterImgHeight = window.getComputedStyle(fotterImage).height;
    const fotterHeight = window.getComputedStyle(footer).height;
    const fotterPaddingTop = window.getComputedStyle(footer).paddingTop;
    const fotterPaddingBottom = window.getComputedStyle(footer).paddingBottom;

    wrapper.style.height = `calc(100vh - ${fotterImgHeight} - ${fotterHeight} - ${fotterPaddingTop} - ${fotterPaddingBottom} - 65px)`;
  }
}
export default handleLayout;
