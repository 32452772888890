import "leaflet/dist/leaflet.css";
import Map from "./Map";
import Chart from "./Chart";
import CustomTable from "./CustomTable";
import MapComuni from "./MapComuni";

function ContentBox(props) {
  const {
    chartData,
    arrowsData,
    mapDescription,
    chartReferenceValue,
    chartUnit,
  } = props;

  function cutEmptyData(valuesArray) {
    const emptyValueIndexes = [];
    valuesArray.forEach((oneYearValue, index) => {
      if (Number.isNaN(parseFloat(oneYearValue.refdata))) {
        emptyValueIndexes.push(index);
      }
    });
    emptyValueIndexes.reverse().forEach((id) => {
      valuesArray.splice(id, 1);
    });
  }
  console.log(chartData)
  return (
    <>
      {props.chartActive && (
        <Chart
          chartData={chartData}
          chartUnit={chartUnit}
          arrowsData={arrowsData}
          activeIndicator={props.activeIndicator}
          mapDescription={mapDescription}
          chartReferenceValue={chartReferenceValue}
          chartType={props.chartDataExists}
        />
      )}
      {props.mapProvinceActive && (
        <Map
          mapDescription={mapDescription}
          cutEmptyData={cutEmptyData}
          currentTargets={props.currentTargets}
          chartData={chartData}
          chartUnit={chartUnit}
          activeIndicator={props.activeIndicator}
        />
      )}
      {props.mapComuniActive && (
        <MapComuni
          mapDescription={mapDescription}
          cutEmptyData={cutEmptyData}
          currentTargets={props.currentTargets}
          chartData={chartData}
          chartUnit={chartUnit}
          activeIndicator={props.activeIndicator}
        />
      )}
      {props.tableActive && (
        <CustomTable
          chartData={chartData}
          chartUnit={chartUnit}
          mapDescription={mapDescription}
          activeIndicator={props.activeIndicator}
          width={props.width}
        />
      )}
    </>
  );
}

export default ContentBox;
