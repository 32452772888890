import React, { useState, useEffect } from "react";

import CotainerNavigation from "./ContainerNavigation";
import ContentBox from "./ContentBox";
import ContentContainerTitle from "./ContentContainerTitle";
import configData from "../../config.json";
import useHttp from "../../hooks/use-http";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

function ContentContainer(props) {
  const { currentTargets, activeIndicator, activeTarget, activeGoal } = props;
  const [chartActive, setChartActive] = useState(true);
  const [mapProvinceActive, setMapProvinceActive] = useState(false);
  const [mapComuniActive, setMapComuniActive] = useState(false);
  const [mapZOActive, setMapZOActive] = useState(false);
  const [tableActive, setTableActive] = useState(false);
  const { error, isLoading, sendRequest: fetchData } = useHttp();
  const [chartData, setChartData] = useState([]);
  const [chartUnit, setChartUnit] = useState(null);
  const [chartReferenceValue, setChartReferenceValue] = useState(null);
  const [arrowsData, setArrowsData] = useState([]);
  const [mapDescription, setMapDescription] = useState(null);
  const [mapDataExists, setMapDataExists] = useState(false);
  const [besDataExists, setBesDataExists] = useState(false);
  const [chartDataExists, setChartDataExists] = useState(false);
  const [tableDataExists, setTableDataExists] = useState(false);

  function checkDataExist(data) {
    setChartDataExists(data.feats.chart);
    setMapDataExists(data.feats.map);
    setTableDataExists(data.feats.table);
    setBesDataExists(data.feats.bes);
  }

  function handleData(data) {
    const updatedData = [];
    let arrowData = [];
    let referenceChartLine;
    const unit = data.notes.measureunit;
    const dataSource = data.notes.datasource;
    const dataMeasureMethod = data.notes.measuremethod;
    const dataNoteTarget = data.notes.target;
    checkDataExist(data);
    setMapDescription({
      source: dataSource,
      measureMethod: dataMeasureMethod,
      noteTarget: dataNoteTarget,
    });
    setChartUnit(unit);

    // we will take all the data without cutting anything with gaps (each gap is set to null), on the chart and tables we willl visualize gaps
    // on the map we have to cut the data so as to take only data which are continous
    // and not visualizing gaps which are in the middle

    data.fractions.forEach((fraction) => {
      let title = fraction.def_json.classe;
      if (
        title === regionalData_API &&
        fraction.def_json.dimensioni !== "comuni"
      ) {
        title = regionalData_FrontEnd;
        if (
          fraction.indicatorstatus.length !== 0 &&
          fraction.indicatorstatus[0].targetvalue !== null
        ) {
          referenceChartLine = parseFloat(
            fraction.indicatorstatus[0].targetvalue.toFixed(2)
          );
        }
      }

      // sort the data accroding to the year
      fraction.indicatorvalues.sort((a, b) => {
        return Number(a.refyear) - Number(b.refyear);
      });

      fraction.indicatorvalues.forEach((value, index) => {
        if (value.refdata !== null) {
          value.refdata.replaceAll(",", ".");
        }
        const yearData = value.refdata;
        const yearName = value.refyear;
        const updatedValue = {
          ...updatedData[index],
          name: yearName,
          [`${title}`]: yearData,
        };
        updatedData[index] = updatedValue;
      });

      // create and safe arrows data to the arrowsDataArray
      // if (fraction.def_json.dimensioni !== "comuni") {
        const oneFractionArrow = fraction.indicatorstatus[0];
        if (oneFractionArrow !== undefined) {
          arrowData.push({ arrowName: title, status: oneFractionArrow });
        }
        // after we went through all frations we should reset the arrowsData array
        const allDataUpdated =
          fraction.fractionid === data.fractions[data.fractions.length - 1].fractionid;
        if (allDataUpdated) {
          // don't display data for the indicator  id:1,8 ( the data is not correct)
          if (
            activeIndicator.data.id === 23
          ) {
            setArrowsData([]);
          } else {
            setArrowsData(arrowData);
          }
          arrowData = [];
        }
      // }
    });
    setChartReferenceValue(referenceChartLine);
    setChartData(updatedData);
  }

  function isDataAndNodeElementEqual(indicator) {
    let isDataEqualNode = false;
    const indicatorNotNull = indicator && indicator.nodeElement;
    if (
      indicatorNotNull &&
      indicator.data.id === Number(indicator.nodeElement.id)
    ) {
      isDataEqualNode = true;
    }
    return isDataEqualNode;
  }
  useEffect(() => {
    const dataEqualNodeElement = isDataAndNodeElementEqual(activeIndicator);
    if (activeIndicator && dataEqualNodeElement) {
      const indicatorId = activeIndicator.data.id;
      console.log(indicatorId)
      fetchData(
        {
          url: `${configData.SERVER_URL}/indicatorsdataforgraphics/${indicatorId}/`,
        },

        handleData
      );
    }
  }, [activeIndicator]);

  // when switch indicators make sure that we will not have the same old container
  useEffect(() => {
    if (chartDataExists.bar || chartDataExists.line) {
      const chartNavBtn = document.body.querySelector("#chart");
      if (chartNavBtn !== null) {
        chartNavBtn.click();
      }
    } else if (mapDataExists.province) {
      const mapNavBtn = document.body.querySelector("#map");
      if (mapNavBtn !== null) {
        mapNavBtn.click();
      }
    } else if (
      !(chartDataExists.bar || chartDataExists.line) &&
      !mapDataExists.province &&
      mapDataExists.comuni
    ) {
      const mapComuniNavBtn = document.body.querySelector("#mapComuni");
      if (mapComuniNavBtn !== null) {
        mapComuniNavBtn.click();
      }
    } else {
      const tableNavBtn = document.body.querySelector("#table");
      if (tableNavBtn !== null) {
        tableNavBtn.click();
      }
    }
  }, [mapDataExists, chartDataExists, tableDataExists]);

  // add margin to the last icon on the left in navigation container bar
  useEffect(() => {
    if (besDataExists) {
      const chartInfoIcon = document.body.querySelector("#chartInfoIcon");
      if (chartInfoIcon !== null) {
        chartInfoIcon.style.marginLeft = "0";
      }
      const besIcon = document.body.querySelector("#besIcon");
      besIcon.style.marginLeft = "auto";
    } else if (tableActive) {
      const chartInfoIcon = document.body.querySelector("#chartInfoIcon");
      if (chartInfoIcon !== null) {
        chartInfoIcon.style.marginLeft = "0";
      }
      const exportTableIcon = document.body.querySelector("#exportTableIcon");
      exportTableIcon.style.marginLeft = "auto";
    } else {
      const chartInfoIcon = document.body.querySelector("#chartInfoIcon");
      chartInfoIcon.style.marginLeft = "auto";
    }
  }, [besDataExists, tableActive, activeIndicator]);

  return (
    <>
      <ContentContainerTitle
        currentTargets={currentTargets}
        activeIndicator={activeIndicator}
        activeTarget={activeTarget}
      />
      <CotainerNavigation
        mapState={{ mapProvinceActive, setMapProvinceActive }}
        mapComuniState={{ mapComuniActive, setMapComuniActive }}
        mapZOState={{ mapZOActive, setMapZOActive }}
        chartState={{ chartActive, setChartActive }}
        tableState={{ tableActive, setTableActive }}
        activeGoal={activeGoal}
        activeIndicator={activeIndicator}
        mapDescription={mapDescription}
        mapDataExists={mapDataExists}
        besDataExists={besDataExists}
        chartDataExists={chartDataExists}
        tableDataExists={tableDataExists}
      />
      <ContentBox
        mapProvinceActive={mapProvinceActive}
        mapComuniActive={mapComuniActive}
        mapZOActive={mapZOActive}
        chartActive={chartActive}
        tableActive={tableActive}
        activeIndicator={activeIndicator}
        currentTargets={currentTargets}
        chartData={chartData}
        chartDataExists={chartDataExists}
        arrowsData={arrowsData}
        mapDescription={mapDescription}
        chartReferenceValue={chartReferenceValue}
        chartUnit={chartUnit}
        width={props.width}
      />
    </>
  );
}

export default ContentContainer;
