import { useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import styles from "./ChartInfo.module.css";

function ChartInfo(props) {
  const [infoBoxActive, setInfoBoxActive] = useState(false);

  function onClickHandler() {
    setInfoBoxActive(!infoBoxActive);
  }

  let containerStyle;
  let textStyle;
  let titleTextStyle;
  let infoIconStyle;

  if (props.tableActive) {
    containerStyle = `${styles.containerForTable}`;
    textStyle = ` ${styles.infoTextForTable}`;
    titleTextStyle = `${styles.infoTextTitleForTable}`;
  } else {
    containerStyle = `${styles.containerForChart}`;
    textStyle = ` ${styles.infoTextForChart}`;
    titleTextStyle = `${styles.infoTextTitleForChart}`;
    infoIconStyle = `${styles.infoIconForChart}`;
  }

  const showInfoIcon =
    (!props.tableActive && props.mapProvinceActive && props.mapDataExists) ||
    (!props.tableActive && !props.mapProvinceActive);

  return (
    <>
      {showInfoIcon && (
        <BsInfoCircleFill
          className={infoIconStyle}
          onClick={onClickHandler}
          id="chartInfoIcon"
          alt="Ulteriori informazioni"
        />
      )}
      {(infoBoxActive || props.tableActive) && props.mapDescription && (
        <div className={containerStyle} id="info_box">
          {props.mapDescription.source && (
            <p className={textStyle}>
              <span className={titleTextStyle}>Fonte:</span>{" "}
              {props.mapDescription.source}
            </p>
          )}
          {props.mapDescription.measureMethod && (
            <p className={textStyle}>
              {" "}
              <span className={titleTextStyle}>Misura:</span>{" "}
              {props.mapDescription.measureMethod}
            </p>
          )}
          {props.mapDescription.noteTarget && (
            <p className={textStyle}>
              {" "}
              <span className={titleTextStyle}>Obiettivo:</span>{" "}
              {props.mapDescription.noteTarget}
            </p>
          )}
        </div>
      )}
    </>
  );
}

export default ChartInfo;
