import { FiMenu } from "react-icons/fi";
import { IoGridSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./NavigationBar.module.css";
import Logo from "./Logo";
import configData from "../../config.json";

function NavigationBar(props) {
  const location = useLocation();

  const mobileNavStyles =
    props.isMobileScreen && props.mobileNavActive
      ? `${styles.nav_items} ${styles.nav_items_active}`
      : `${styles.nav_items}`;
  const onClickHamburgerHandler = () => {
    props.setMobileLegendActive(false);
    props.setMobileNavActive(!props.mobileNavActive);
  };
  const onClickGridHandler = () => {
    props.setMobileNavActive(false);
    props.setMobileLegendActive(!props.mobileLegendActive);
  };

  const chooseActivePage = (navItemId) => {
    if (navItemId === "dashboard") {
      props.setNavigationBarActive({
        dashboardActive: true,
        sdgPageActive: false,
        developmentPageActive: false,
        infoPageActive: false,
      });
    } else if (navItemId === "sdgPage") {
      props.setNavigationBarActive({
        dashboardActive: false,
        sdgPageActive: true,
        developmentPageActive: false,
        infoPageActive: false,
      });
    } else if (navItemId === "developmentPage") {
      props.setNavigationBarActive({
        dashboardActive: false,
        sdgPageActive: false,
        developmentPageActive: true,
        infoPageActive: false,
      });
    } else if (navItemId === "infoPage") {
      props.setNavigationBarActive({
        dashboardActive: false,
        sdgPageActive: false,
        developmentPageActive: false,
        infoPageActive: true,
      });
    }
  };

  const onClickNavLinkHandler = (event) => {
    props.setMobileNavActive(false);
    props.setWheelActive(false);
    chooseActivePage(event.target.id);
  };

  const dashboardStyles = props.navigationBarActive.dashboardActive
    ? `${styles.nav_link} ${styles.nav_link_active}`
    : `${styles.nav_link}`;
  const sdgPageStyles = props.navigationBarActive.sdgPageActive
    ? `${styles.nav_link} ${styles.nav_link_active}`
    : `${styles.nav_link}`;
  const developmentPageStyles = props.navigationBarActive.developmentPageActive
    ? `${styles.nav_link} ${styles.nav_link_active}`
    : `${styles.nav_link}`;
  const infoPageStyles = props.navigationBarActive.infoPageActive
    ? `${styles.nav_link} ${styles.nav_link_active}`
    : `${styles.nav_link}`;
  return (
    <nav>
      <ul className={styles.nav_list}>
        <div className={styles.logo_wrapper}>
          <li className={styles.nav_link}>
            <Logo
              className={styles.logo}
              setWheelActive={props.setWheelActive}
              activeWheel={props.activeWheel}
            />
          </li>
        </div>
        <div className={mobileNavStyles}>
          <NavLink
            className={dashboardStyles}
            to="/"
            onClick={onClickNavLinkHandler}
            id="dashboard"
          >
            Dashboard
          </NavLink>
          <NavLink
            className={developmentPageStyles}
            to="/trend_di_sviluppo"
            onClick={onClickNavLinkHandler}
            id="developmentPage"
          >
            Trend di sviluppo
          </NavLink>
          <NavLink
            className={sdgPageStyles}
            to="/indicatori_SDG"
            onClick={onClickNavLinkHandler}
            id="sdgPage"
          >
            Gli Obiettivi
          </NavLink>
          <a
            href={configData.EXTERNAL_URL.INFO_LINK}
            className={infoPageStyles}
            target="_blank"
            rel="noopener noreferrer"
            id="infoPage"
          >
            Agenda 2030 CM Genova
          </a>
          <a
            href={configData.EXTERNAL_URL.BACKOFFICE}
            className={infoPageStyles}
            target="_blank"
            rel="noopener noreferrer"
            id="infoPage"
          >
            Area riservata
          </a>
        </div>
        <div className={styles.icons_mobile}>
          <FiMenu className={styles.icon} onClick={onClickHamburgerHandler} />
          {location.pathname === "/" && (
            <IoGridSharp className={styles.icon} onClick={onClickGridHandler} />
          )}
        </div>
      </ul>
    </nav>
  );
}

export default NavigationBar;
