import { ImArrowUp } from "react-icons/im";
import { BsCheckLg } from "react-icons/bs";
import styles from "./ArrowsContainer.module.css";
import configData from "../../config.json";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Liguria
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Genova
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Genova

function ArrowsContainer(props) {
  const { arrowsData } = props;
  // check whether arrows exist for particular data
  function findArrowsByName(dataName) {
    let arrow;
    arrowsData.forEach((arrowData) => {
      if (arrowData.arrowName.toUpperCase() === dataName.toUpperCase()) {
        arrow = arrowData;
      }
    });
    return arrow;
  }
  function checkPartCountryData(dataName, arrowPeriod) {
    let arrowType;
    if (arrowPeriod === "short") {
      arrowType = "shortterm_arrow";
    } else if (arrowPeriod === "long") {
      arrowType = "longterm_arrow";
    }
    return (
      findArrowsByName(dataName) !== undefined &&
      findArrowsByName(dataName).status[`${arrowType}`]
    );
  }
  const countryShortArrows =
    arrowsData.length > 0 &&
    findArrowsByName(countryData) !== undefined &&
    findArrowsByName(countryData).status.shortterm_arrow;

  const countryLongArrows =
    arrowsData.length > 0 &&
    findArrowsByName(countryData) !== undefined &&
    findArrowsByName(countryData).status.longterm_arrow;

  const countryDataExists =
    (countryShortArrows || countryLongArrows) && props.chartData.length > 0;

  const partCountryShortArrows =
    arrowsData.length > 0 &&
    (checkPartCountryData(partCountryData_2, "short") ||
      checkPartCountryData(partCountryData_1, "short"));

  const partCountryLongArrows =
    arrowsData.length > 0 &&
    (checkPartCountryData(partCountryData_2, "long") ||
      checkPartCountryData(partCountryData_1, "long"));

  const partCountryDataExists =
    (partCountryShortArrows || partCountryLongArrows) &&
    props.chartData.length > 0;

  const provinceShortArrows =
    arrowsData.length > 0 &&
    findArrowsByName(provinceData) !== undefined &&
    findArrowsByName(provinceData).status.shortterm_arrow;

  const provinceLongArrows =
    arrowsData.length > 0 &&
    findArrowsByName(provinceData) !== undefined &&
    findArrowsByName(provinceData).status.longterm_arrow;

  const provinceDataExists =
    (provinceShortArrows || provinceLongArrows) && props.chartData.length > 0;

  const euShortArrows =
    arrowsData.length > 0 &&
    findArrowsByName(euData) !== undefined &&
    findArrowsByName(euData).status.shortterm_arrow;

  const euLongArrows =
    arrowsData.length > 0 &&
    findArrowsByName(euData) !== undefined &&
    findArrowsByName(euData).status.longterm_arrow;

  const euDataExists =
    (euShortArrows || euLongArrows) && props.chartData.length > 0;

  const regionalShortArrows =
    arrowsData.length > 0 &&
    findArrowsByName(regionalData_FrontEnd) !== undefined &&
    findArrowsByName(regionalData_FrontEnd).status.shortterm_arrow;

  const regionalLongArrows =
    arrowsData.length > 0 &&
    findArrowsByName(regionalData_FrontEnd) !== undefined &&
    findArrowsByName(regionalData_FrontEnd).status.longterm_arrow;

  const regionalDataExists =
    (regionalShortArrows || regionalLongArrows) && props.chartData.length > 0;

  // check whether short and long period data for arrows exists
  const shortArrowsDataExist =
    countryShortArrows ||
    partCountryShortArrows ||
    provinceShortArrows ||
    euShortArrows ||
    regionalShortArrows;
  const longArrowsDataExist =
    countryLongArrows ||
    partCountryLongArrows ||
    provinceLongArrows ||
    euLongArrows ||
    regionalLongArrows;

  function findTitle(data, name) {
    let result;
    Object.keys(data[0]).forEach((title) => {
      if (title === name) {
        result = title;
      }
      if (title === partCountryData_2 && name === partCountryData_1) {
        result = partCountryData_2;
      }
    });
    return result;
  }
  function styleArrows(arrowStatus) {
    let arrowStyle;
    // green arrow up
    if (arrowStatus === 1) {
      arrowStyle = `${styles.green_arrow}`;
    } else if (arrowStatus === 2) {
      arrowStyle = `${styles.green_arrow} ${styles.arrow_rotate_45}`;
    } else if (arrowStatus === 3) {
      arrowStyle = `${styles.red_arrow} ${styles.arrow_rotate_135}`;
    } else if (arrowStatus === 4) {
      arrowStyle = `${styles.red_arrow} ${styles.arrow_rotate_180}`;
    }
    return arrowStyle;
  }
  // delete the padding-bottom when there is only one one type of arrow ( either short period or long period)
  const oneTypeOfArrowAvailable = !shortArrowsDataExist || !longArrowsDataExist;
  const arrowsDataAvailable = shortArrowsDataExist || longArrowsDataExist;

  const arrowsContainer = document.body.querySelector(
    ".ArrowsContainer_arrow_group__Oa44I"
  );
  if (arrowsContainer !== null && oneTypeOfArrowAvailable) {
    arrowsContainer.style.paddingBottom = 0;
  } else if (arrowsContainer !== null && !oneTypeOfArrowAvailable) {
    arrowsContainer.style.paddingBottom = "1em";
  }

  function isArrowCompleted(arrowStatus) {
    return arrowStatus === 5;
  }
  // visualize correct arrow or checked icon depending on the data
  function visualizeIcon(indicatorStatus) {
    return !isArrowCompleted(indicatorStatus) ? (
      <ImArrowUp className={styleArrows(indicatorStatus)} />
    ) : (
      <BsCheckLg className={styles.green_arrow} />
    );
  }
  const styleContainerTitleMobile = `${styles.containerTitle} ${styles.containerTitleMobile}`;
  const styleContainerTitleNotMobile = `${styles.containerTitle} ${styles.containerTitleNotMobile}`;

  return (
    <>
      {arrowsDataAvailable && (
        <p className={styleContainerTitleMobile}>Andamento</p>
      )}
      <div className={styles.container}>
        {!arrowsDataAvailable && (
          <p className={styles.noDataText}>
            Non sono ancora disponibili dati sufficienti per il calcolo
            dell&apos;andamento a breve e lungo termine
          </p>
        )}
        {arrowsDataAvailable && (
          <>
            <p className={styleContainerTitleNotMobile}>Andamento</p>
            <div className={styles.arrow_group}>
              {shortArrowsDataExist && (
                <p className={styles.arrow_group_title}>Breve periodo</p>
              )}
              <div className={styles.arrows_container}>
                {regionalDataExists && regionalShortArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_5} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, regionalData_FrontEnd)}
                    </p>
                    {visualizeIcon(regionalShortArrows)}
                  </div>
                )}

                {provinceDataExists && provinceShortArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_1} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, provinceData)}
                    </p>
                    {visualizeIcon(provinceShortArrows)}
                  </div>
                )}
                {partCountryDataExists && partCountryShortArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_2} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, partCountryData_1)}
                    </p>
                    {visualizeIcon(partCountryShortArrows)}
                  </div>
                )}
                {countryDataExists && countryShortArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_3} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, countryData)}
                    </p>
                    {visualizeIcon(countryShortArrows)}
                  </div>
                )}
                {euDataExists && euShortArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_4} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, euData)}
                    </p>
                    {visualizeIcon(euShortArrows)}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.arrow_group}>
              {longArrowsDataExist && (
                <p className={styles.arrow_group_title}>Lungo periodo</p>
              )}

              <div className={styles.arrows_container}>
                {regionalDataExists && regionalLongArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_5} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, regionalData_FrontEnd)}
                    </p>
                    {visualizeIcon(regionalLongArrows)}
                  </div>
                )}
                {provinceDataExists && provinceLongArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_1} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, provinceData)}
                    </p>
                    {visualizeIcon(provinceLongArrows)}
                  </div>
                )}
                {partCountryDataExists && partCountryLongArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_2} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, partCountryData_1)}
                    </p>
                    {visualizeIcon(partCountryLongArrows)}
                  </div>
                )}
                {countryDataExists && countryLongArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_3} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, countryData)}
                    </p>
                    {visualizeIcon(countryLongArrows)}
                  </div>
                )}
                {euDataExists && euLongArrows && (
                  <div className={styles.arrow_container}>
                    <p className={`${styles.title_4} ${styles.arrow_title}`}>
                      {findTitle(props.chartData, euData)}
                    </p>
                    {visualizeIcon(euLongArrows)}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ArrowsContainer;
