import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
} from "recharts";
import styles from "./ChartBar.module.css";
import ArrowsContainer from "./ArrowsContainer";
import configData from "../../config.json";

const roadConditionsM = configData.DATA.PUMS.CATTIVE_CONDIZIONI_STRADALI_MOLTO;
const connectionPublicTransportM =
  configData.DATA.PUMS.DIFFICOLTA_DI_COLLEGAMENTO_CON_MEZZI_PUBBLICI_MOLTO;
const parkingM = configData.DATA.PUMS.DIFFICOLTA_DI_PARCHEGGIO_MOLTO;
const roadIlluminationM =
  configData.DATA.PUMS.SCARSA_ILLUMINAZIONE_STRADALE_MOLTO;
const trafficM = configData.DATA.PUMS.TRAFFICO_MOLTO;
const cmGenova = configData.DATA.REGIONE_DATA_FRONTEND;
const province = configData.DATA.PROVINCE_DATA;

function ChartBar(props) {
  const {
    chartData,
    chartDataRounded,
    chartUnit,
    arrowsData,
    axisRange,
    mapDescription,
    activeIndicator,
  } = props;

  // set textAnchor to middle to center the yAxis
  const yAxisTitle = document.querySelector(".recharts-text.recharts-label");
  if (yAxisTitle !== null) {
    yAxisTitle.classList.add(`${styles.yAxisTitle}`);
  }
  return (
    mapDescription && (
      <div className={styles.container}>
        <ArrowsContainer
          chartData={chartData}
          arrowsData={arrowsData}
          activeIndicator={props.activeIndicator}
        />
        <ResponsiveContainer
          width="90%"
          height="100%"
          className={styles.responsive_container}
        >
          <BarChart
            className={styles.chart}
            data={chartDataRounded}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              className={styles.yAxisTitle}
              width={45}
              label={{
                value: chartUnit,
                angle: -90,
                position: "insideLeft",
                offset: -12,
              }}
              domain={axisRange}
            />
            <Tooltip />
            {activeIndicator.data.id === 33 && (
              <ReferenceLine
                y={0}
                strokeWidth={2}
                stroke="black"
                strokeDasharray="3 3"
              />
            )}
            <Legend />
            {chartData[0] !== undefined &&
              chartData[0][roadConditionsM] !== undefined && (
                <Bar dataKey={roadConditionsM} fill="#4bc9ff" />
              )}
            {chartData[0] !== undefined &&
              chartData[0][connectionPublicTransportM] !== undefined && (
                <Bar dataKey={connectionPublicTransportM} fill="#6aa84f" />
              )}
            {chartData[0] !== undefined &&
              chartData[0][parkingM] !== undefined && (
                <Bar dataKey={parkingM} fill="#cc0000" />
              )}
            {chartData[0] !== undefined &&
              chartData[0][roadIlluminationM] !== undefined && (
                <Bar dataKey={roadIlluminationM} fill="#0b4e8a" />
              )}
            {chartData[0] !== undefined &&
              chartData[0][trafficM] !== undefined && (
                <Bar dataKey={trafficM} fill="#f1c232" />
              )}
            {chartData[0] !== undefined &&
              chartData[0][cmGenova] !== undefined && (
                <Bar dataKey={cmGenova} fill="#cc0000" />
              )}
            {chartData[0] !== undefined &&
              chartData[0][province] !== undefined && (
                <Bar dataKey={province} fill="#0b4e8a" />
              )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  );
}

export default React.memo(ChartBar);
