import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./DevelopmentTrend.module.css";
import Footer from "../UI/Footer";
import configData from "../config.json";

import button1 from "../data/trend_di_sviluppo/button1.svg";
import button2 from "../data/trend_di_sviluppo/button2.svg";
import button3 from "../data/trend_di_sviluppo/button3.svg";

import trendTitle from "../data/trend_di_sviluppo/Genova/TrendTitle.svg";
import trend1a from "../data/trend_di_sviluppo/Genova/Trend1a.svg";
import trend1b from "../data/trend_di_sviluppo/Genova/Trend1b.svg";
import trend1c from "../data/trend_di_sviluppo/Genova/Trend1c.svg";
import trend1d from "../data/trend_di_sviluppo/Genova/Trend1d.svg";
import trend1e from "../data/trend_di_sviluppo/Genova/Trend1e.svg";
import trend1f from "../data/trend_di_sviluppo/Genova/Trend1f.svg";
import trend1g from "../data/trend_di_sviluppo/Genova/Trend1g.svg";
import trend1h from "../data/trend_di_sviluppo/Genova/Trend1h.svg";
import trend1i from "../data/trend_di_sviluppo/Genova/Trend1i.svg";

import trend2a from "../data/trend_di_sviluppo/Genova/Trend2a.svg";
import trend2b from "../data/trend_di_sviluppo/Genova/Trend2b.svg";
import trend2c from "../data/trend_di_sviluppo/Genova/Trend2c.svg";
import trend2d from "../data/trend_di_sviluppo/Genova/Trend2d.svg";
import trend2e from "../data/trend_di_sviluppo/Genova/Trend2e.svg";
import trend2f from "../data/trend_di_sviluppo/Genova/Trend2f.svg";

import trendPumsA from "../data/trend_di_sviluppo/Genova/TrendPumsA.svg";
import trendPumsB from "../data/trend_di_sviluppo/Genova/TrendPumsB.svg";
import trendPumsC from "../data/trend_di_sviluppo/Genova/TrendPumsC.svg";

import goal2_icon from "../data/Goals/Goal_02.svg";
import goal6_icon from "../data/Goals/Goal_06.svg";
import goal7_icon from "../data/Goals/Goal_07.svg";
import goal11_icon from "../data/Goals/Goal_11.svg";
import goal12_icon from "../data/Goals/Goal_12.svg";
import goal13_icon from "../data/Goals/Goal_13.svg";

import goal1_icon from "../data/Goals/Goal_01.svg";
import goal4_icon from "../data/Goals/Goal_04.svg";
import goal5_icon from "../data/Goals/Goal_05.svg";
import goal8_icon from "../data/Goals/Goal_08.svg";
import goal10_icon from "../data/Goals/Goal_10.svg";

import goal_pums from "../data/Goals/PUMS.svg";

import arrowsExplanationsImg from "../data/GoalDefinitions/FrecceSpiegazioneNoCharts.svg";

function DevelopmentTrend(props) {
  const [button1Active, setButton1Active] = useState(true);
  const [button2Active, setButton2Active] = useState(false);
  const [button3Active, setButton3Active] = useState(false);
  const navigate = useNavigate();

  function onClickButton1Handler() {
    if (button1Active === false) {
      setButton1Active(true);
      setButton2Active(false);
      setButton3Active(false);
    }
  }
  function onClickButton2Handler() {
    if (button2Active === false) {
      setButton1Active(false);
      setButton2Active(true);
      setButton3Active(false);
    }
  }
  function onClickButton3Handler() {
    if (button3Active === false) {
      setButton1Active(false);
      setButton2Active(false);
      setButton3Active(true);
    }
  }
  const activeBtn1Style = button1Active
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn2Style = button2Active
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn3Style = button3Active
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;

  const secondTrendSubtitle = `${styles.trendSubtitle} ${styles.trendIndicatorsSubtitle}`;

  const currentURL = window.location.origin;

  function checkIndicatorInGoal(indicatorId) {
    const isFirstIndicatorInGoal =
      configData.DATA.INDICATORS_FIRST_IN_GOAL.find(
        (id) => id === Number(indicatorId)
      );
    return isFirstIndicatorInGoal;
  }
  // activating the indicator after clicking on the trend di sviluppo page
  function activateIndicator(event) {
    const goalId = event.target.attributes["data-goalid"].value;
    const indicatorId = event.target.attributes["data-indicatorid"].value;
    navigate("../", { replace: true });
    props.setNavigationBarActive({
      dashboardActive: true,
      sdgPageActive: false,
      developmentPageActive: false,
      infoPageActive: false,
    });

    setTimeout(() => {
      const goalToActivate = document.body.querySelector(`.goalIcon_${goalId}`);
      goalToActivate.click();
    }, 150);

    // if the indicator is the first in the goal then we should not activate it
    // because the first indicator is actiaved already when we click on the goal
    if (checkIndicatorInGoal(indicatorId)) return;
    setTimeout(() => {
      const indicatorToActivate = document.body.querySelector(
        `[data-indicatorid='${indicatorId}']`
      );
      indicatorToActivate.click();
    }, 650);
  }
  return (
    <div className={styles.main}>
      <div
        className={styles.introWrapper}
        style={{
          backgroundImage: `url(${currentURL}/img/introImg.jpg)`,
        }}
      >
        <div className={styles.introTitleBox} />
        <h3 className={styles.introTitle}>Trend di sviluppo</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.intro}>
          <p className={styles.introText}>
            Città Metropolitana di Genova ha raccolto gli indicatori più
            significativi legati ad alcuni tematismi strategici per lo sviluppo
            del territorio e delle sue comunità. La selezione, trasversale
            rispetto ai 17 Obiettivi di Sviluppo Sostenibile dell’Agenda 2030,
            evidenzia l’interconnessione tra le diverse tematiche e l’approccio
            organico che Città Metropolitana di Genova adotta per raggiungere
            gli obiettivi prefissati.
          </p>
        </div>
        <div className={styles.trendsWrapper}>
          <div className={styles.buttonsContainer}>
            <img
              src={button1}
              className={activeBtn1Style}
              alt="Comunità Resilienti"
              onClick={onClickButton1Handler}
            />
            <img
              src={button2}
              className={activeBtn2Style}
              alt="Benessere e Inclusività"
              onClick={onClickButton2Handler}
            />
            <img
              src={button3}
              className={activeBtn3Style}
              alt="Mobilità Sostenibile"
              onClick={onClickButton3Handler}
            />
          </div>
          {button1Active && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Comunità resilienti</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                  Comprendere attraverso gli indicatori selezionati come la
                  Città Metropolitana e le comunità che la abitano siano in
                  grado di rispondere in modo tempestivo agli eventi
                  perturbativi, imparando ad essere più flessibili e attivi
                  nell’affrontare le difficoltà, rendendo le città e gli
                  insediamenti umani più inclusivi, sicuri, sostenibili e
                  autosufficienti.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img
                  src={goal6_icon}
                  className={styles.goalImage}
                  alt="Acqua pulita e servizi igienico-sanitari"
                />
                <img
                  src={goal11_icon}
                  className={styles.goalImage}
                  alt="Città e comunità sostenibili"
                />
                <img
                  src={goal12_icon}
                  className={styles.goalImage}
                  alt="Consumo e produzione responsabili"
                />
                <img
                  src={goal13_icon}
                  className={styles.goalImage}
                  alt="Lotta contro il cambiamento climatico"
                />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img
                  src={trendTitle}
                  className={styles.trendImage}
                  alt="Intestazione tabella"
                />
                <img
                  src={trend1a}
                  className={styles.trendImage}
                  alt="Trattamento delle acque reflue"
                  data-goalid="6"
                  data-indicatorid="3"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1b}
                  className={styles.trendImage}
                  alt="Abusivismo edilizio"
                  data-goalid="11"
                  data-indicatorid="9"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1c}
                  className={styles.trendImage}
                  alt="Popolazione esposta al rischio di alluvioni"
                  data-goalid="11"
                  data-indicatorid="10"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1d}
                  className={styles.trendImage}
                  alt="Popolazione esposta al rischio di frane"
                  data-goalid="11"
                  data-indicatorid="11"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1e}
                  className={styles.trendImage}
                  alt="Rifiuti urbani raccolti"
                  data-goalid="11"
                  data-indicatorid="12"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1f}
                  className={styles.trendImage}
                  alt="Incidenza delle aree di verde urbano in città"
                  data-goalid="11"
                  data-indicatorid="13"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1g}
                  className={styles.trendImage}
                  alt="Raccolta differenziata dei rifiuti urbani"
                  data-goalid="12"
                  data-indicatorid="14"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1h}
                  className={styles.trendImage}
                  alt="Aree protette"
                  data-goalid="15"
                  data-indicatorid="15"
                  onClick={activateIndicator}
                />
                <img
                  src={trend1i}
                  className={styles.trendImage}
                  alt="Suolo consumato"
                  data-goalid="15"
                  data-indicatorid="16"
                  onClick={activateIndicator}
                />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Genova,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * che fanno riferimento a dati
                regionali.
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          {button2Active && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>
                  Benessere e inclusività
                </h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                  Comprendere attraverso gli indicatori selezionati come la
                  Città Metropolitana contribuisce a promuovere la coesistenza e
                  la valorizzazione delle differenze, migliorare la qualità di
                  vita e le connessioni, garantendo pari opportunità ai suoi
                  cittadini.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img
                  src={goal4_icon}
                  className={styles.goalImage}
                  alt="Istruzione di qualità"
                />
                <img
                  src={goal8_icon}
                  className={styles.goalImage}
                  alt="Lavoro dignitoso e cresita economica"
                />
                <img
                  src={goal10_icon}
                  className={styles.goalImage}
                  alt="Ridurre le disuguaglianze"
                />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img
                  src={trendTitle}
                  className={styles.trendImage}
                  alt="Intestazione tabella"
                />
                <img
                  src={trend2a}
                  className={styles.trendImage}
                  alt="Usicita precoce dal sistema d'istruzione e formazione"
                  data-goalid="4"
                  data-indicatorid="1"
                  onClick={activateIndicator}
                />
                <img
                  src={trend2b}
                  className={styles.trendImage}
                  alt="Competenze digitali elevate"
                  data-goalid="4"
                  data-indicatorid="2"
                  onClick={activateIndicator}
                />
                <img
                  src={trend2c}
                  className={styles.trendImage}
                  alt="Tasso di occupazione(20-64 anni)"
                  data-goalid="8"
                  data-indicatorid="5"
                  onClick={activateIndicator}
                />
                <img
                  src={trend2d}
                  className={styles.trendImage}
                  alt="Giovani che non lavorano e non studiano (NEET)"
                  data-goalid="8"
                  data-indicatorid="6"
                  onClick={activateIndicator}
                />
                <img
                  src={trend2e}
                  className={styles.trendImage}
                  alt="Disuguaglianza del reddito netto(s80/s20)"
                  data-goalid="10"
                  data-indicatorid="7"
                  onClick={activateIndicator}
                />
                <img
                  src={trend2f}
                  className={styles.trendImage}
                  alt="Reddito medio disponibile pro capite"
                  data-goalid="10"
                  data-indicatorid="8"
                  onClick={activateIndicator}
                />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Genova,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * che fanno riferimento a dati
                regionali.
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          {button3Active && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Mobilità sostenibile</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                  Comprendere attraverso gli indicatori selezionati come la
                  Città Metropolitana favorisca il miglioramento della qualità
                  della vita mediante politiche volte a promuovere una mobilità
                  più sostenibile di persone e merci, specialmente all'interno
                  delle aree urbane.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal_pums} className={styles.goalImage} alt="PUMS" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img
                  src={trendTitle}
                  className={styles.trendImage}
                  alt="Intestazione tabella"
                />
                <img
                  src={trendPumsA}
                  className={styles.trendImage}
                  alt="Autobus a basse emissioni utilizzati per il TPL"
                  data-goalid="18"
                  data-indicatorid="31"
                  onClick={activateIndicator}
                />
                <img
                  src={trendPumsB}
                  className={styles.trendImage}
                  alt="Posti-km offerti dal TPL"
                  data-goalid="18"
                  data-indicatorid="30"
                  onClick={activateIndicator}
                />
                <img
                  src={trendPumsC}
                  className={styles.trendImage}
                  alt="Mortalità stradale in ambito extraurbano"
                  data-goalid="18"
                  data-indicatorid="32"
                  onClick={activateIndicator}
                />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Genova,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * che fanno riferimento a dati
                regionali.
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default DevelopmentTrend;
