import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import configData from "../../config.json";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

const roadConditionsM = configData.DATA.PUMS.CATTIVE_CONDIZIONI_STRADALI_MOLTO;
const roadConditionsMA =
  configData.DATA.PUMS.CATTIVE_CONDIZIONI_STRADALI_MOLTO_ABBASTANZA;
const connectionPublicTransportM =
  configData.DATA.PUMS.DIFFICOLTA_DI_COLLEGAMENTO_CON_MEZZI_PUBBLICI_MOLTO;
const connectionPublicTransportMA =
  configData.DATA.PUMS
    .DIFFICOLTA_DI_COLLEGAMENTO_CON_MEZZI_PUBBLICI_MOLTO_ABBASTANZA;
const parkingM = configData.DATA.PUMS.DIFFICOLTA_DI_PARCHEGGIO_MOLTO;
const parkingMA =
  configData.DATA.PUMS.DIFFICOLTA_DI_PARCHEGGIO_MOLTO_ABBASTANZA;
const roadIlluminationM =
  configData.DATA.PUMS.SCARSA_ILLUMINAZIONE_STRADALE_MOLTO;
const roadIlluminationMA =
  configData.DATA.PUMS.SCARSA_ILLUMINAZIONE_STRADALE_MOLTO_ABBASTANZA;
const trafficM = configData.DATA.PUMS.TRAFFICO_MOLTO;
const trafficMA = configData.DATA.PUMS.TRAFFICO_MOLTO_ABBASTANZA;

const co2_total = configData.DATA.PUMS.CO2_TOTALE;
const co2_auto = configData.DATA.PUMS.CO2_AUTOMOBILI;
const co2_bus = configData.DATA.PUMS.CO2_BUS;
const co2_moto = configData.DATA.PUMS.CO2_MOTO;
const co2_commercial_vehicle = configData.DATA.PUMS.CO2_VEICOLI_COMMERCIALI;
const nox_total = configData.DATA.PUMS.NOX_TOTALE;
const nox_auto = configData.DATA.PUMS.NOX_AUTOMOBILI;
const nox_bus = configData.DATA.PUMS.NOX_BUS;
const nox_moto = configData.DATA.PUMS.NOX_MOTO;
const nox_commercial_vehicle = configData.DATA.PUMS.NOX_VEICOLI_COMMERCIALI;
const pm10_total = configData.DATA.PUMS.PM10_TOTALE;
const pm10_auto = configData.DATA.PUMS.PM10_AUTOMOBILI;
const pm10_bus = configData.DATA.PUMS.PM10_BUS;
const pm10_moto = configData.DATA.PUMS.PM10_MOTO;

const genova_total = configData.DATA.PUMS.GENOVA_TOTALE;
const genova_autobus = configData.DATA.PUMS.GENOVA_AUTOBUS;
const genova_filobus = configData.DATA.PUMS.GENOVA_FILOBUS;
const genova_metro = configData.DATA.PUMS.GENOVA_METROPOLITANA;
const genova_electric_hybrid = configData.DATA.PUMS.GENOVA_ELECTRIC_HYBRID;
const genova_methane = configData.DATA.PUMS.GENOVA_METHANE;
const imperia_total = configData.DATA.PUMS.IMPERIA_TOTALE;
const imperia_autobus = configData.DATA.PUMS.IMPERIA_AUTOBUS;
const imperia_filobus = configData.DATA.PUMS.IMPERIA_FILOBUS;
const imperia_metro = configData.DATA.PUMS.IMPERIA_METROPOLITANA;
const imperia_electric_hybrid = configData.DATA.PUMS.IMPERIA_ELECTRIC_HYBRID;
const imperia_methane = configData.DATA.PUMS.IMPERIA_METHANE;
const la_spezia_total = configData.DATA.PUMS.LA_SPEZIA_TOTALE;
const la_spezia_autobus = configData.DATA.PUMS.LA_SPEZIA_AUTOBUS;
const la_spezia_filobus = configData.DATA.PUMS.LA_SPEZIA_FILOBUS;
const la_spezia_metro = configData.DATA.PUMS.LA_SPEZIA_METROPOLITANA;
const la_spezia_electric_hybrid =
  configData.DATA.PUMS.LA_SPEZIA_ELECTRIC_HYBRID;
const la_spezia_methane = configData.DATA.PUMS.LA_SPEZIA_METHANE;
const savona_total = configData.DATA.PUMS.SAVONA_TOTALE;
const savona_autobus = configData.DATA.PUMS.SAVONA_AUTOBUS;
const savona_filobus = configData.DATA.PUMS.SAVONA_FILOBUS;
const savona_metro = configData.DATA.PUMS.SAVONA_METROPOLITANA;
const savona_electric_hybrid = configData.DATA.PUMS.SAVONA_ELECTRIC_HYBRID;
const savona_methane = configData.DATA.PUMS.SAVONA_METHANE;

const rowsToVisualize = [
  euData,
  countryData,
  partCountryData_1,
  partCountryData_2,
  provinceData,
  regionalData_FrontEnd,
  roadConditionsM,
  roadConditionsMA,
  connectionPublicTransportM,
  connectionPublicTransportMA,
  parkingM,
  parkingMA,
  roadIlluminationM,
  roadIlluminationMA,
  trafficM,
  trafficMA,
  co2_total,
  co2_auto,
  co2_bus,
  co2_moto,
  co2_commercial_vehicle,
  nox_total,
  nox_auto,
  nox_bus,
  nox_moto,
  nox_commercial_vehicle,
  pm10_total,
  pm10_auto,
  pm10_bus,
  pm10_moto,
  genova_total,
  genova_autobus,
  genova_filobus,
  genova_metro,
  imperia_total,
  imperia_autobus,
  imperia_filobus,
  imperia_metro,
  la_spezia_total,
  la_spezia_autobus,
  la_spezia_filobus,
  la_spezia_metro,
  savona_total,
  savona_autobus,
  savona_filobus,
  savona_metro,
  genova_electric_hybrid,
  genova_methane,
  imperia_electric_hybrid,
  imperia_methane,
  la_spezia_electric_hybrid,
  la_spezia_methane,
  savona_electric_hybrid,
  savona_methane,
];
function CustomTable(props) {
  const [tableHeight, setTableHeight] = useState(null);
  const { chartData, chartUnit } = props;
  const noSmallScreen = useMediaQuery("(min-width:500px)");

  function checkDisplayData(row) {
    let visualizeData = false;
    const rowFound = rowsToVisualize.find((rowName) => row.name === rowName);
    if (rowFound !== undefined) {
      visualizeData = true;
    } else {
      visualizeData = false;
    }
    return visualizeData;
  }

  // we will check if chartData > than 60 means that we will have comuni

  function checkComuniData() {
    if (Object.keys(chartData[0]).slice(1).length > 60) {
      Object.keys(chartData[0])
        .slice(1)
        .forEach((comuneName) => {
          rowsToVisualize.push(comuneName);
        });
    }
  }

  function prepareTableData() {
    checkComuniData();
    const data = [];
    Object.entries(chartData[0])
      .slice(1)
      .forEach((title) => {
        data.push({ name: title[0] });
      });
    data.map((row, index) => {
      chartData.forEach((column) => {
        rowsToVisualize.forEach((rowName) => {
          if (row.name === rowName) {
            data[index] = {
              ...data[index],
              [`${`year_${column.name}`}`]: `${column[rowName]}`,
            };
          }
        });
      });
    });
    return data;
  }

  // calculate dynamically the max height of the table ( consider the breakpoint 1260px )
  function calculateTableHeight() {
    const containerStyles = window.getComputedStyle(
      document.querySelector(".content_container")
    );
    const containerHeight = parseFloat(containerStyles.height.slice(0, -2));
    const containerTitleHeight = parseFloat(
      window
        .getComputedStyle(document.getElementById("container_title"))
        .height.slice(0, -2)
    );
    const containerNavigationStyles = window.getComputedStyle(
      document.getElementById("container_navigation")
    );
    const containerNavigationPaddingTop = parseFloat(
      containerNavigationStyles.paddingTop.slice(0, -2)
    );
    const containerNavigationPaddingBottom = parseFloat(
      containerNavigationStyles.paddingBottom.slice(0, -2)
    );
    const containerNavigationHeight =
      parseFloat(containerNavigationStyles.height.slice(0, -2)) +
      containerNavigationPaddingTop +
      containerNavigationPaddingBottom;
    const infoBox = document.getElementById("info_box");

    if (infoBox === null) return;
    const info_boxHeight = parseFloat(
      window.getComputedStyle(infoBox).height.slice(0, -2)
    );

    if (window.innerWidth > 1260) {
      setTableHeight(
        containerHeight -
          containerTitleHeight -
          containerNavigationHeight -
          info_boxHeight -
          32
      );
    } else {
      setTableHeight(
        containerHeight - containerNavigationHeight - info_boxHeight - 32
      );
    }
  }

  useEffect(() => {
    calculateTableHeight();
  }, [props.activeIndicator, props.width]);

  const rowsData = prepareTableData();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(150, 150, 150)",
      color: theme.palette.common.white,
      fontSize: noSmallScreen ? 14 : 10,
      minWidth: noSmallScreen ? 70 : 50,
      maxWidth: noSmallScreen ? 70 : 50,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: noSmallScreen ? 14 : 10,
      minWidth: noSmallScreen ? 70 : 50,
      maxWidth: noSmallScreen ? 70 : 50,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableContainer = styled(TableContainer)({
    maxHeight: `${tableHeight}px`,
    maxWidth: "calc(100vw - 3rem)",
    "@media (min-width:801px) and (max-width:1260px)": {
      maxWidth: "calc(100vw - 270px - 3rem)",
    },
    "@media (min-width:1260px) and (max-width:1499px)": {
      maxWidth: "calc(100vw - 270px - 270px - 5rem)",
    },
    "@media (min-width:1500px)": {
      maxWidth: "calc(100vw - 318px - 334px - 5rem)",
    },
  });

  return (
    <StyledTableContainer
      sx={{ minWidth: "100%", maxHeight: `${tableHeight}px` }}
      component={Paper}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{chartUnit}</StyledTableCell>
            {chartData.map((column) => {
              return (
                <StyledTableCell align="right" key={column.name}>
                  {column.name}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((row) => {
            const displayData = checkDisplayData(row);
            if (!displayData) return "";
            return (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                {Object.entries(row)
                  .slice(1)
                  .map((year) => {
                    const valueToShow = Number.isNaN(parseFloat(year[1]))
                      ? ""
                      : parseFloat(year[1]).toFixed(1);
                    return (
                      <StyledTableCell align="right" key={year}>
                        {valueToShow}
                      </StyledTableCell>
                    );
                  })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

export default CustomTable;
