import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./Wheel.module.css";
import wheelImage from "../data/wheel/SDG_Wheel_CM_Genova.svg";
import logoImage from "../data/wheel/Logo_CM_Genova.svg";
import genovaImg_3 from "../data/frontPageImg_3.jpg";
import genovaImg_2 from "../data/frontPageImg_2.jpg";
import handleLayout from "../helper/handleFrontPageLayout";

function Wheel(props) {
  const imageRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: "800px" });

  // adjust layout to cover a whole screen
  setTimeout(() => {
    handleLayout();
  }, 500);

  function onClickHandler() {
    imageRef.current.animate(
      [
        // keyframes

        { transform: "rotate(0)" },
        { transform: "rotate(360deg)" },
      ],
      // timing options
      {
        duration: 3000,
      }
    );
    // we wait 1.5 second and we toggle the visibility of wheel
    setTimeout(() => {
      props.setWheelActive(!props.wheelActive);
      props.setNavigationBarActive({
        dashboardActive: true,
        sdgPageActive: false,
        developmentPageActive: false,
        infoPageActive: false,
      });
      // click on the dashboard button in the navigation bar (when we do refreash and we're on different page we want to make sure that after clicking wheel we will always go to the dashboard page)
      const dashboarNavBtn = document.getElementById("dashboard");
      dashboarNavBtn.click();
    }, 1500);
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} id="wheel_wrapper">
        <div className={styles.img_text_contianer}>
          <img
            ref={imageRef}
            className={styles.goalIcon}
            src={wheelImage}
            alt="Datalab dashboard di monitoraggio degli obiettivi di sviluppo sostenibile"
            onClick={onClickHandler}
          />
          <div className={styles.textContainer}>
            {!props.isMobileScreen && (
              <img
                className={styles.logoIcon}
                src={logoImage}
                alt="Logo Città Metropolitana di Genova"
              />
            )}
            <p className={styles.introText}>
              Datalab è un cruscotto di analisi e monitoraggio degli indicatori
              dei Sustainable Development Goals legati agli obiettivi e target
              dall’Agenda Metropolitana Sostenibile, nonché degli indicatori del
              P.U.M.S., Piano Urbano della Mobilità Sostenibile della Città
              Metropolitana di Genova.
            </p>
            <button
              className={styles.exploreBtn}
              onClick={onClickHandler}
              tabIndex={0}
              type="submit"
            >
              Esplora
            </button>
          </div>
        </div>
      </div>
      <div className={styles.genova_img_wrapper} id="genova_img">
        {isMobile && (
          <img
            src={genovaImg_2}
            className={styles.genova_img}
            alt="Logo Footer"
          />
        )}
        {!isMobile && (
          <img
            src={genovaImg_3}
            className={styles.genova_img}
            alt="Logo Footer"
          />
        )}
      </div>
    </div>
  );
}
export default Wheel;
