import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "./DescriptionSidebar.module.css";
import "react-responsive-carousel/lib/styles/carousel.css";
import SelectBox from "./SelectBox";
import IndicatorText from "./IndicatorText";
import "./CarouselArrows.css";

function DescriptionSidebar(props, ref) {
  const classesCarouselContainer = `${styles.container} ${styles.containerCarousel}`;
  const classesBigScreenContainer = `${styles.container} ${styles.containerBigScreen}`;
  const { isLoading, error, currentTargets } = props;

  if (isLoading) {
    return (
      <div className={`${styles.message_container} ${styles.loading}`}>
        <p className={styles.loading_text}>Loading data...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div className={`${styles.message_container} ${styles.error}`}>
        <p className={styles.error_text}>{error}</p>
      </div>
    );
  }

  let smallScreenContent;
  let bigScreenContent;
  if (currentTargets.length !== 0) {
    smallScreenContent = currentTargets.map((target) => (
      <div
        className={styles.target_container}
        key={target.id}
        style={{ backgroundColor: `${target.color}` }}
      >
        <p className={styles.target_short_name}>{target.description}</p>
        <img
          alt={`L'icona di ${target.description}`}
          className={styles.icon}
          src={target.icon}
        />
        <div className={styles.text_box}>
          <SelectBox
            indicators={target.indicators}
            setActiveIndicator={props.setActiveIndicator}
            activeIndicator={props.activeIndicator}
            currentTargets={currentTargets}
            setActiveTarget={props.setActiveTarget}
            targetColor={target.color}
          />
        </div>
      </div>
    ));
    bigScreenContent = currentTargets.map((target) => (
      <div className={styles.target_container} key={target.id}>
        <div
          className={styles.icon_container}
          style={{ backgroundColor: `${target.color}` }}
        >
          <img
            alt={`L'icona di ${target.description}`}
            className={styles.icon}
            src={target.icon}
          />
        </div>
        <p
          className={`${styles.target_short_name} ${styles.target_short_name_bigScreen}`}
          style={{ backgroundColor: `${target.color}` }}
        >
          {`${target.num !== null ? target.num : ""} ${target.description}`}
        </p>
        {target.indicators.map((indicator) => (
          <IndicatorText
            key={indicator.id}
            indicator={indicator}
            setActiveTarget={props.setActiveTarget}
            activeTarget={props.activeTarget}
            currentTargets={currentTargets}
            setActiveIndicator={props.setActiveIndicator}
            activeIndicator={props.activeIndicator}
            targetId={target.id}
            indicators={target.indicators}
            color={target.color}
          />
        ))}
      </div>
    ));
  }
  let targetIdInTheGoal;
  if (props.activeTarget != null) {
    currentTargets.forEach((target, index) => {
      if (props.activeTarget.id === target.id) {
        targetIdInTheGoal = index;
      }
    });
  }
  // set always the first indicator as an active, on the swipe of the carousel
  function handleOnSwipeEnd(index) {
    currentTargets.forEach((target, targetId) => {
      if (targetId === index) {
        targetIdInTheGoal = index;
        props.setActiveTarget(target);
        props.setActiveIndicator({
          data: target.indicators[0],
          nodeElement: null,
        });
      }
    });
  }

  return (
    <>
      {/* // Small screen sidebar (handle in css file with media query) */}
      {!props.isBigScreen && (
        <div className={classesCarouselContainer}>
          <Carousel
            className={styles.carousel}
            axis="horizontal"
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            selectedItem={targetIdInTheGoal}
            onChange={handleOnSwipeEnd}
          >
            {smallScreenContent}
          </Carousel>
        </div>
      )}
      {/* // Big screen sidebar (handle in css file with media query) */}
      <div className={classesBigScreenContainer} ref={ref}>
        {bigScreenContent}
      </div>
    </>
  );
}
export default React.forwardRef(DescriptionSidebar);
