import styles from "./Footer.module.css";
import configData from "../config.json";
import coloureeLogo from "../data/Logo_Colouree.svg";

function Footer() {
  const email1 = `mailto: ${configData.FOOTER.EMAIL_1}`;
  const logo1 = configData.FOOTER.ICON_1;
  return (
    <div className={styles.footer} id="footer">
      Città Metropolitana di Genova - P.le Mazzini 2, 16122 Genova | centralino
      010 54991 | &nbsp;
      <a href={email1} className={styles.mail_link}>
        PEC - Posta Elettronica Certificata
      </a>
      &nbsp;|&nbsp; CF 80007350103 &nbsp;| P.IVA 00949170104 <br /> powered by
      <a
        href={logo1}
        className={styles.logo_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={coloureeLogo}
          className={styles.logo_icon}
          alt="Colouree logo"
        />
      </a>
    </div>
  );
}

export default Footer;
