import export_csv from "../../data/DownloadIcons/ExportCSV.svg";
import export_xls from "../../data/DownloadIcons/ExportXLS.svg";
import styles from "./ContainerNavigation.module.css";

import ChartInfo from "./ChartInfo";
import BesInfo from "./BesInfo";

function ContainerNavigation(props) {
  const { mapProvinceActive, setMapProvinceActive } = props.mapState;
  const { mapComuniActive, setMapComuniActive } = props.mapComuniState;
  const { chartActive, setChartActive } = props.chartState;
  const { tableActive, setTableActive } = props.tableState;

  const onClickHandler = (event) => {
    if (event.target.id === "chart") {
      setChartActive(true);
      setMapProvinceActive(false);
      setMapComuniActive(false);
      setTableActive(false);
    }
    if (event.target.id === "map") {
      setChartActive(false);
      setMapProvinceActive(true);
      setMapComuniActive(false);
      setTableActive(false);
    }
    if (event.target.id === "mapComuni") {
      setChartActive(false);
      setMapProvinceActive(false);
      setMapComuniActive(true);
      setTableActive(false);
    }
    if (event.target.id === "table") {
      setChartActive(false);
      setMapProvinceActive(false);
      setMapComuniActive(false);
      setTableActive(true);
    }
  };
  const chartItemClass = chartActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;
  const mapItemClass = mapProvinceActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;
  const mapComuniItemClass = mapComuniActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;
  const tableItemClass = tableActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;

  const showProvinceMap =
    props.activeIndicator !== undefined &&
    props.activeIndicator !== null &&
    props.mapDataExists.province;

  const showComuniMap =
    props.activeIndicator !== undefined &&
    props.activeIndicator !== null &&
    props.mapDataExists.comuni;

  const showChart = props.chartDataExists.line || props.chartDataExists.bar;
  const showTable = props.tableDataExists;

  let exportXlsUrl;
  let exportCsvUrl;

  if (props.activeIndicator !== null) {
    exportXlsUrl = `https://trf2.colouree.com/export/xls/${props.activeIndicator.data.id}`;
    exportCsvUrl = `https://trf2.colouree.com/export/csv/${props.activeIndicator.data.id}`;
  } 

  return (
    <div className={styles.nav_bar} id="container_navigation">
      <ul className={styles.nav_list}>
        {showChart && (
          <li className={chartItemClass} onClick={onClickHandler} onKeyPress={(event) => event.code==='Enter' ? onClickHandler(event) : ''} id="chart" tabIndex="1" >
            Diagramma
          </li>
        )}
        {showProvinceMap && (
          <li className={mapItemClass} onClick={onClickHandler} onKeyPress={(event) => event.code==='Enter' ? onClickHandler(event) : ''} id="map" tabIndex="1" >
            Mappa
          </li>
        )}
        {showComuniMap && (
          <li
            className={mapComuniItemClass}
            onClick={onClickHandler}
            onKeyPress={(event) => event.code==='Enter' ? onClickHandler(event) : ''}
            id="mapComuni"
            tabIndex="1"
          >
            Mappa Comuni
          </li>
        )}
        {showTable && (
          <li className={tableItemClass} onClick={onClickHandler} onKeyPress={(event) => event.code==='Enter' ? onClickHandler(event) : ''} id="table" tabIndex="1">
            Tabella
          </li>
        )}
        {props.besDataExists && (
          <BesInfo className={styles.bes_icon} alt="Informazioni sul BES" />
        )}
        <ChartInfo
          mapDescription={props.mapDescription}
          tableActive={tableActive}
          mapProvinceActive={mapProvinceActive}
          mapDataExists={props.mapDataExists}
        />
        {tableActive && (
          <div id="exportTableIcon" className={styles.download_icons_wrapper}>
            <a href={exportXlsUrl} download target="_blank" rel="noreferrer">
              <img
                src={export_xls}
                alt="Scarica i dati in formato excel - xls"
                className={styles.download_icon}
              />
            </a>
            <a href={exportCsvUrl} download target="_blank" rel="noreferrer">
              <img
                src={export_csv}
                alt="Scarica i dati in formato excel - csv"
                className={styles.download_icon}
              />
            </a>
          </div>
        )}
      </ul>
    </div>
  );
}
export default ContainerNavigation;
