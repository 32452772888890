import L from "leaflet";
import { useEffect } from "react";
import styles from "./MapLegend.module.css";

function MapLegend(props) {
  useEffect(() => {
    setTimeout(() => {
      if (props.map !== null) {
        const controlDiv = document.body.querySelector(".legendContainer");

        if (controlDiv !== null) {
          controlDiv.remove();
        }

        const dataIsAvailable = props.legendRange.length !== 0;
        const { maxValue } = props.legendRange;
        const range = props.legendRange.maxValue - props.legendRange.minValue;

        const interval = range / 8;

        const getColor = (d) => {
          const color = props.matchColor(d, maxValue, interval);
          return color;
        };

        const legend = L.control({ position: "bottomright" });

        legend.onAdd = () => {
          const div = L.DomUtil.create(
            "div",
            `${styles.info} ${styles.legend} legendContainer`
          );
          if (dataIsAvailable) {
            const grades = [
              (maxValue - interval * 8).toFixed(1),
              (maxValue - interval * 7).toFixed(1),
              (maxValue - interval * 6).toFixed(1),
              (maxValue - interval * 5).toFixed(1),
              (maxValue - interval * 4).toFixed(1),
              (maxValue - interval * 3).toFixed(1),
              (maxValue - interval * 2).toFixed(1),
              (maxValue - interval).toFixed(1),
              maxValue.toFixed(1),
            ];
            const labels = [];
            let from;
            let to;

            for (let i = 0; i < grades.length; i += 1) {
              from = grades[i];
              to = grades[i + 1];
              const roundedFrom =
                parseFloat(from) < 100 ? from : parseFloat(from).toFixed(0);
              const roundedTo =
                parseFloat(to) < 100 ? to : parseFloat(to).toFixed(0);
              labels.push(
                `<i style="background:${getColor(
                  from + 1
                )}"></i> ${roundedFrom}${to ? ` &ndash; ${roundedTo}` : "+"}`
              );
            }
            div.innerHTML = `<p class='${styles.unitText} unitText'>${
              props.chartUnit
            }</p> ${labels.join("<br>")}`;
            return div;
          }
          const information = "Dati non disponibili a scala provinciale";
          const color = "#abd7df";
          div.innerHTML = `<i style="background:${color}"></i> ${information}`;
          return div;
        };
        props.map.addControl(legend);
      }
    }, 400);
  }, [
    props.map,
    props.activeIndicator,
    props.legendRange,
    props.currentYear,
    props.chartUnit,
    props.colorScale,
  ]);
  return "";
}

export default MapLegend;
